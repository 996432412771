import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  let element = document.getElementById("smsConfirm");

  if (element == null) {
    return;
  }

  let token = document.querySelector("input[name=token]");

  let app = new Vue({
    el: "#smsConfirm",
    data: {
      token: token || "", // Подставляем пустую строку, если токен отсутствует
    },

    methods: {

      send_sms(event) {
        event.preventDefault()

        $("body").addClass("is-hidden");
        $("#modal-donate").addClass("is-show");
    
       let formAction = "/send_sms_cards";

        const formData = new FormData();
        formData.append("authenticity_token", this.token);
        formData.append("confirm", true);

        axios
          .post(formAction, formData)
          .then((res) => {
            console.log(res)
          })
          .catch(function (error) {
            console.log(error)

          });
      },
    },
  });
});
 